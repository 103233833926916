import { NextPage } from "next";
import { NextSeo } from "next-seo";

import { SignInScreen } from "@mps/pro-app/features/auth/screens/signIn";
import { guestOnlyGetSSP } from "utils/guestOnly";

const SignInPage: NextPage = () => (
	<>
		<NextSeo canonical="https://pro.monpetitsoin.fr/connexion" />
		<SignInScreen />
	</>
);

export const getServerSideProps = guestOnlyGetSSP();

export default SignInPage;
